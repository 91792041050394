import React, { useRef, useCallback, useEffect, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ScholarShipCertificate from '../Components/ScholarshipCertificate';
import MainContent from '../Components/MainContent';
import RightBar from '../Components/RightBar';
import Calendar from '../Components/calendar/Calendar';
import LeaderBoard from '../Components/LeaderBoard';

function CertificatePage() {
  const certificateRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const { cohortType, cohortId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const handlePrint = useReactToPrint({
    content: () => certificateRef.current,
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     handlePrint();
  //   }, 3000);
  // }, [handlePrint]);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex w-full justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/certificates"
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Certificates</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        {isMobileView ? (
          <div className="bg-white my-4 justify-center align-middle max-h-[100vh] py-6">
            <h1 className="text-xl text-justify px-4 font-semibold">
              To ensure a great user experience and avoid any issues with your
              certificate, please access this page from a desktop or laptop.
            </h1>
          </div>
        ) : (
          <div className="my-4">
            <div className="w-auto bg-white text-black px-4 py-2">
              <h2 className="font-semibold uppercase underline underline-offset-2 text-lg">
                How to Download Your Certificate:
              </h2>
              <ol className="space-y-2">
                <li>
                  <p>
                    Complete our brief survey before downloading your
                    certificate. If you have already done so, you may proceed to
                    the next steps. Access the survey{' '}
                    <a
                      href="https://bit.ly/plp_feedback"
                      className="text-claret-600 underline font-bold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Here
                    </a>
                    .
                  </p>
                </li>
                <li>
                  In the print settings, select the{' '}
                  <span className="font-semibold">destination</span> you prefer:
                  choose &quot;Save as PDF&quot; if you want to save a digital
                  copy, or select your printer if you wish to print the
                  certificate.
                </li>
                <li>
                  Ensure that the{' '}
                  <span className="font-semibold">paper size</span> is set to A4
                  in the print settings.
                </li>
                <li>
                  Set the layout to{' '}
                  <span className="font-semibold">landscape</span> instead of
                  portrait.
                </li>
                <li>Click save to download your certificate.</li>
              </ol>
            </div>

            <div className="flex justify-center w-full">
              <button
                type="button"
                onClick={() => handlePrint()}
                className="bg-claret-500 text-white w-1/3 text-xs rounded-md py-2 my-8"
              >
                Download Certificate
              </button>
            </div>
            <div style={{ visibility: 'hidden' }} className="">
              <div
                ref={certificateRef}
                className="h-full overflow-hidden bg-white"
              >
                {cohortType === 'scholarship' ? (
                  <ScholarShipCertificate scholarshipCohortId={cohortId} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        )}
      </MainContent>
      <RightBar>
        <LeaderBoard />
        <Calendar />
      </RightBar>
    </>
  );
}

export default CertificatePage;
